import React, { Component, useState } from 'react'
import Loading from './../../component/Loading';
import PageHeader from './../../Include/PageHeader'
import axios from 'axios';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Cube from '../../Animation/Cube';
import Helper from '../../Helper';
import CarCard from '../../component/CarCard';
import { Link } from '@reach/router';
import FinanceCalculator from './include/FinanceCalculator';
import VerticalPreviewImages from '../../component/VerticalPreviewImages';
import Shared from './../../component/Shared';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Thumbs } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default class CarDetails extends Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            data: false,
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.setState({
                is_loading: true,
                data: false,
            }, () => {
                this.fetch();
            })
        }
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        let res = await axios.get(`${window.api_host}/web/inventory/${this.props.id}`);
        // console.log(res)
        let data = false;
        if (res.data.success) {
            data = res.data.data;
        }
        this.setState({
            is_loading: false,
            data: data
        })

        // Change the title
        let metaTitle = `${data.brand} ${data.model || ''} ${data.minor || ''} ${data.submodel || ''} (${data.year}) `;
        document.title = metaTitle;

        // Update meta tags
        let tags = `รับซื้อ, รับขาย, รับซื้อ - ขาย, รถยุโรป, มือสอง, มือสองสภาพดี, การันตีประวัติทุกคัน ,ไมล์แท้, ไม่มีชนหนัก, ไม่มีย้อมแมว`
        const metaKeywords = document.querySelector('meta[name="keywords"]');
        if (metaKeywords) {
            metaKeywords.setAttribute("content", tags);
        } else {
            const newMetaKeywords = document.createElement('meta');
            newMetaKeywords.setAttribute('name', 'keywords');
            newMetaKeywords.setAttribute('content', tags);
            document.head.appendChild(newMetaKeywords);
        }
        const ogTitle = document.querySelector('meta[property="og:title"]');
        if (metaTitle) {
            ogTitle.setAttribute("content", metaTitle);
        } else {
            const newOgTitle = document.createElement('meta');
            newOgTitle.setAttribute('property', 'og:title');
            newOgTitle.setAttribute('content', metaTitle);
            document.head.appendChild(newOgTitle);
        }
        if (data.carDescription) {
            const metaDescription = document.querySelector('meta[name="description"]');
            if (metaDescription) {
                metaDescription.setAttribute("content", data.carDescription);
            } else {
                const newMeta = document.createElement('meta');
                newMeta.setAttribute('name', 'description');
                newMeta.setAttribute('content', data.carDescription);
                document.head.appendChild(newMeta);
            }
            const ogDescription = document.querySelector('meta[property="og:description"]');
            if (ogDescription) {
                ogDescription.setAttribute("content", data.carDescription);
            } else {
                const newOgDescription = document.createElement('meta');
                newOgDescription.setAttribute('property', 'og:description');
                newOgDescription.setAttribute('content', data.carDescription);
                document.head.appendChild(newOgDescription);
            }
        }
        if (data.fileImageCar?.thumbnailURL350 || data.fileImageCar?.imagePath) {
            const ogImage = document.querySelector('meta[property="og:image"]');
            if (ogImage) {
                ogImage.setAttribute("content", data.fileImageCar?.thumbnailURL350 || data.fileImageCar?.imagePath);
            } else {
                const newOgImage = document.createElement('meta');
                newOgImage.setAttribute('property', 'og:image');
                newOgImage.setAttribute('content', data.fileImageCar?.thumbnailURL350 || data.fileImageCar?.imagePath);
                document.head.appendChild(newOgImage);
            }
        }
    }

    render() {
        let data = this.state.data;
        let images = [];
        if (data && data.fileImageCar) {
            images.push(data.fileImageCar)
        }
        if (data && data.moreCarImages?.length > 0) {
            data.moreCarImages.map((image, i) => {
                images.push(image)
            })
        }
        // console.log(images)

        return (
            <div style={{ position: 'relative' }}>
                <section className='ourscars pt-0'>

                    {
                        this.state.is_loading ?
                            <div style={{ paddingTop: 100, paddingBottom: 120 }}><Loading /></div>
                            : null
                    }
                    {
                        !this.state.is_loading
                            && data ?
                            <>
                                <div className='container mt-3'>
                                    <CarPageHeader
                                        data={data}
                                    />

                                    {
                                        images.length > 0 ?
                                            <>
                                                {/* <CarCarouselSlick images={images} /> */}
                                                <CarCarouselSwiper images={images} />
                                            </>
                                            : null
                                    }
                                </div>

                                {
                                    data ?
                                        <>
                                            <div className='new-cars'>
                                                <div className='container' style={{ position: 'relative', zIndex: 10 }}>
                                                    <h2 className='mb-2 text-left'>{data.brand} </h2>
                                                    {
                                                        data.is_reserve
                                                            || data.status.id == '1' ?
                                                            <div className='mb-3 mt-3'>
                                                                {
                                                                    data.is_reserve ?
                                                                        <span className="chip pink ml-0 mr-4">
                                                                            <i className="las la-user-tag"></i> ติดจอง
                                                                        </span>
                                                                        : null
                                                                }
                                                                {
                                                                    data.status.id == '1' ?
                                                                        <span className="chip warning ml-0">
                                                                            <i className="las la-wrench"></i> กำลังซ่อม
                                                                        </span>
                                                                        : null
                                                                }
                                                            </div>
                                                            : null
                                                    }
                                                    <h4 className='mb-2 ttext-left text-muted text-bold'>
                                                        {data.model}
                                                        {` `}
                                                        {data.submodel || data.minor ? <><span className="bullet bullet-dot"></span></> : null}
                                                        {data.minor ? ` ${data.minor}` : null}
                                                        {data.submodel ? ` ${data.submodel}` : null}
                                                    </h4>
                                                    <Shared />
                                                    {
                                                        data.sellingPrice ?
                                                            <div className='d-flex '>
                                                                <p className='car-price'>฿{Helper.numberFormat(data.sellingPrice)}</p>
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        data.carDescription ?
                                                            <p style={{ whiteSpace: 'pre-wrap' }}>{data.carDescription}</p>
                                                            : null
                                                    }
                                                    <h3 className='text-center mb-3' style={{ fontWeight: 'bold' }}>ติดต่อซื้อขาย</h3>
                                                    <div className='d-flex justify-content-center align-items-center flex-wrap about-us pt-0 pb-0'>
                                                        <a href="tel:0991464646" target={"_blank"} className='d-flex custom-link ml-3 mr-3 mb-3'>
                                                            <i className="fas fa-phone mr-1 d-flex justify-content-center align-items-center " style={{ fontSize: 24 }}></i>
                                                            <p className='mb-0'>099-146-4646</p>
                                                        </a>
                                                        <a href="https://lin.ee/2BwZQnijS" target={"_blank"} className='d-flex justify-content-center align-items-center ml-3 mr-3 mb-3 custom-link'>
                                                            <img src={`${window.location.origin}/assets/images/social/line.png`} style={{ height: 30 }} />
                                                            <p className='ml-1 mb-0'>@Champearthmotors</p>
                                                        </a>
                                                    </div>
                                                    <FinanceCalculator
                                                        data={data}
                                                    />
                                                </div>
                                                <Cube />
                                            </div>
                                            <MoreCars data={data} />

                                        </>
                                        : null
                                }

                            </>
                            : null
                    }
                    {
                        !this.state.is_loading
                            && !data ?
                            <div className='container mt-3'>
                                <PageHeader title={"ไม่มีข้อมูลรถ"} />

                            </div>
                            : null
                    }
                </section>
            </div>
        )
    }
}


class CarCarouselSlick extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }
    render() {
        let images = this.props.images;
        const settings = {
            infinite: true,
            fade: true,
            arrows: false,
            adaptiveHeight: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        }
        return (
            <div className='pb-5'>
                <Slider
                    {...settings}
                    asNavFor={this.slider2}
                    ref={slider => (this.slider1 = slider)}
                >
                    {
                        images.map((image, index) => {
                            return (
                                <div key={index}>
                                    <div className="car-carousel-item"
                                        onClick={() => {
                                            Helper.reactToDom(window.$('body'),
                                                <VerticalPreviewImages
                                                    images={images}
                                                    selectedIndex={index}
                                                />
                                            )
                                        }}
                                    >

                                        <img src={image.imagePath} style={{ maxWidth: '100%' }} />

                                    </div>
                                </div>
                            )
                        })
                    }

                </Slider>
                {
                    images.length > 2 ?
                        <div className='thumb-pos'>
                            <Slider
                                asNavFor={this.slider1}
                                ref={slider => (this.slider2 = slider)}
                                slidesToShow={images.length > 9 ? 9 : images.length}
                                swipeToSlide={false}
                                focusOnSelect={true}
                                centerMode={true}
                                variableWidth={true}
                                infinite={true}
                                className={'thumbnail-image'}
                                responsive={[
                                    {
                                        breakpoint: 1024,
                                        settings: {
                                            slidesToShow: 5,
                                            slidesToScroll: 3,
                                        }
                                    },
                                    {
                                        breakpoint: 600,
                                        settings: {
                                            slidesToShow: 5,
                                            slidesToScroll: 1,
                                            initialSlide: 1,
                                            infinite: false,
                                        }
                                    },
                                    {
                                        breakpoint: 480,
                                        settings: {
                                            slidesToShow: 3,
                                            slidesToScroll: 1,
                                            centerMode: false,
                                            adaptiveHeight: false,
                                            infinite: false,
                                        }
                                    }
                                ]}
                            >
                                {
                                    images.map((image, index) => {
                                        return (
                                            <div key={index}>
                                                <div className='image-thumb'>
                                                    <img src={image.imagePath} style={{ maxWidth: '100%' }} />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </Slider>
                        </div>
                        : null
                }

            </div>
        )
    }
}


class CarPageHeader extends Component {
    render() {
        let data = this.props.data;
        return (
            <section className='cover'>
                <div className='container' style={{ position: 'relative' }}>
                    <div className='row'>
                        <div className='col-lg-7 slide-info page-inside'>
                            <h1>champ earth <small style={{ color: '#E36C78' }}>motors</small></h1>
                            {
                                data ?
                                    <>
                                        <h2 className='mb-2'>{data.brand}
                                            <h4 className='mb-0'>
                                                {data.model}
                                                {` `}
                                                {data.submodel || data.minor ? <><span className="bullet bullet-dot"></span></> : null}
                                                {data.minor ? ` ${data.minor}` : null}
                                                {data.submodel ? ` ${data.submodel}` : null}
                                            </h4>
                                        </h2>

                                    </>
                                    : null
                            }
                            {
                                data.sellingPrice ?
                                    <div className='d-flex '>
                                        <p className='car-price'>฿{Helper.numberFormat(data.sellingPrice)}</p>
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}


class MoreCars extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            list: [],
        }
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        let res = await axios.get(`${window.api_host}/web/more-cars/${this.props.data.uid}`);
        // console.log(res)
        let list = [];
        if (res.data.success) {
            list = res.data.moreCars
        }
        this.setState({
            list: list,
            is_loading: false
        })
    }

    render() {
        if (!this.state.is_loading && this.state.list.length == 0) {
            return null;
        }
        return (
            <section className='ourscars'>
                <h2 className='mb-5'>More Cars</h2>
                <div className='container'>
                    {
                        this.state.is_loading ?
                            <div style={{ paddingTop: 30 }}><Loading /></div>
                            : null
                    }
                    {
                        !this.state.is_loading
                            && this.state.list.length > 0 ?
                            <div className='row justify-content-center'>
                                {
                                    this.state.list.map((data, key) => {
                                        return (
                                            <div className='col-4 col-md-4 mb-4' key={key}>
                                                <CarCard
                                                    data={data}
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            : null
                    }
                </div>
            </section>
        )
    }
}


function CarCarouselSwiper(props) {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    return (

        <div className='pb-5'>
            <Swiper
                modules={[Thumbs]} thumbs={{ swiper: thumbsSwiper }}
                style={{ marginBottom: 15 }}
            >
                {
                    props.images.map((image, index) => {
                        return (
                            <SwiperSlide key={index}>
                                <div className="car-carousel-item"
                                    onClick={() => {
                                        Helper.reactToDom(window.$('body'),
                                            <VerticalPreviewImages
                                                images={props.images}
                                                selectedIndex={index}
                                            />
                                        )
                                    }}
                                >

                                    <img src={image.imagePath} style={{ maxWidth: '100%' }} />

                                </div>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>

            <Swiper
                spaceBetween={50} slidesPerView={window.innerWidth < 700 ? 4 : window.innerWidth < 950 ? 6 : window.innerWidth < 1200 ? 8 : 10}
                modules={[Thumbs]}
                watchSlidesProgress
                onSwiper={setThumbsSwiper}
            >

                {
                    props.images.map((image, index) => {
                        return (
                            <SwiperSlide key={index}>
                                <div className='image-thumb'>
                                    <img src={image.imagePath} style={{ maxWidth: '100%' }} />
                                </div>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
        </div>
    )
}